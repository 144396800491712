export const timeToDegrees = (time: string | number): number => {
  const [hour, minutes = 0] = time
    .toString()
    .split(":")
    .map(x => parseInt(x, 10));
  // thanks to https://en.wikipedia.org/wiki/Clock_angle_problem
  return 0.5 * (60 * hour + minutes);
};

export const streetToRadius = (streetName: string): number => {
  switch (
    streetName
      .toString()
      .toLowerCase()
      .trim()
  ) {
    case "esp":
    case "esplanade":
      return 2500;
    case "a":
      return 2950;
    case "b":
      return 3200;
    case "c":
      return 3450;
    case "d":
      return 3650;
    case "e":
      return 3900;
    case "f":
      return 4150;
    case "g":
      return 4400;
    case "h":
      return 4650;
    case "i":
      return 4850;
    case "j":
      return 5100;
    case "k":
      return 5350;
    case "l":
      return 5600;
    default:
      return 0;
  }
};

export const degreesToRadians = (angleInDegrees: number): number =>
  (Math.PI * angleInDegrees) / 180;

export const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
): [number, number] => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return [
    centerX + radius * Math.cos(angleInRadians),
    centerY + radius * Math.sin(angleInRadians)
  ];
};

export function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
): string {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);
  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start[0],
    start[1],
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end[0],
    end[1]
  ].join(" ");

  return d;
}

export function cartToPolar([cX, cY]: [number, number]): [number, number] {
  const theta = degreesToRadians(cX) - Math.PI / 2;
  const x = cY * Math.cos(theta);
  const y = cY * Math.sin(theta);
  return [x, y];
}

export const range = (count: number): number[] =>
  Array.from(Array(count).keys());

export function toCartesian({ r, theta }) {
  return [r * Math.cos(theta), r * Math.sin(theta)];
}
