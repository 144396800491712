export const camps = {
  main: [
    {
      name: "Camp Questionmark",
      color: "yellow",
      coords: [["2:01", "esp"], ["2:15", "esp"], ["2:15", "a"], ["2:01", "a"]]
    },
    {
      name: "DISORIENT",
      color: "yellow",
      coords: [["2:15", "esp"], ["2:29", "esp"], ["2:29", "a"], ["2:15", "a"]]
    },
    {
      name: "ERB & Glow Village",
      color: "yellow",
      coords: [["2:31", "esp"], ["2:38", "esp"], ["2:38", "a"], ["2:31", "a"]]
    },
    {
      name: "Gigsville",
      color: "yellow",
      coords: [["2:38", "esp"], ["2:55", "esp"], ["3:00", "a"], ["2:38", "a"]]
    }
  ],
  center: [
    {
      name: "Playa Info",
      color: "yellow",
      coords: [[15, 50], [37, 50], [37, 100], [23, 100], [23, 75], [15, 75]]
    },
    {
      name: "Ranger HQ",
      color: "red",
      coords: [[23, 100], [37, 100], [37, 150], [23, 150]]
    },
    {
      name: "Fire",
      color: "orange",
      coords: [[15, 100], [23, 100], [23, 150], [15, 150]]
    },
    {
      name: "Black Rock Boutique",
      color: "green",
      coords: [[37, 50], [61, 50], [61, 100], [37, 100]]
    },
    {
      name: "BMIR",
      color: "green",
      coords: [[37, 100], [44, 100], [44, 150], [37, 150]]
    },
    {
      name: "Earth Guardians",
      color: "yellow",
      coords: [[44, 100], [53, 100], [53, 150], [44, 150]]
    },
    {
      name: "Temple Guardians",
      color: "pink",
      coords: [[53, 100], [60, 100], [60, 150], [53, 150]]
    },
    {
      name: "Vulcan Empire",
      color: "pink",
      coords: [[60, 100], [69, 100], [69, 150], [60, 150]]
    },
    {
      name: "Dog",
      color: "red",
      coords: [[69, 100], [83, 100], [83, 150], [69, 150]]
    },
    {
      name: "???",
      color: "red",
      coords: [[87, 100], [94, 100], [94, 150], [87, 150]]
    }
  ]
};
