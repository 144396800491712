import { loopifyInPairs } from "@bentobots/list";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { render } from "react-dom";
import { camps } from "./data";
import Drawing from "./models/drawing";
import { cartToPolar, degreesToRadians, describeArc, polarToCartesian, range, streetToRadius, timeToDegrees, toCartesian } from "./utils";

function ObserveDrawing(props) {
  const d = Drawing.create({});
  d.addPoint(100, 100);

  return (
    <div>
      {d.path}
      <Observer>{() => <div>{d.path}</div>}</Observer>
      <button onClick={() => d.addPoint(Math.random(), Math.random())}>
        add
      </button>
    </div>
  );
}

const SideBar = () => {
  return (
    <div id="sidebar">
      <h1>Black Rock Wiki</h1>
      {/* <ObserveDrawing /> */}
      <p>This site is <strong>very much under construction</strong> and only about 5% done. Congratulations for finding it! I'm currently testing with 2017 data. Fully editable 2018 and 2019 BRC maps coming soon ♡ <a href="mailto: hello@blackrock.wiki">get in touch</a></p>
      <h2>2017 Camps</h2>
      <h4>Main</h4>
      <Observer>{() => <table><tbody>
        {camps.main.map(({name}) => name).sort().map(name => <tr key={name}><th>{name}</th></tr>)}
        </tbody></table>}</Observer>
      <h4>Center</h4>
      <Observer>{() => <table><tbody>
        {camps.center.map(({name}) => name).sort().map(name => <tr key={name}><th>{name}</th></tr>)}
        </tbody></table>}</Observer>
    </div>
  );
};

function coordsToPath(coords): string {
  const [first, ...rest] = coords;

  const finalCoords = [first, ...rest, first].map(([angle, radius]) => {
    let a = angle;
    let r = radius;
    if (typeof angle === "string") a = timeToDegrees(angle);
    if (typeof radius === "string") r = streetToRadius(radius);
    return cartToPolar([a, r]);
  });

  const pairs = loopifyInPairs(finalCoords);

  return pairs.reduce((path, [start, end], index) => {
    if (index === 0) {
      // move to first point
      return `${path} M ${end.toString()}`;
    } else {
      if (start[1] === end[1]) {
        // arc
        return `${path} ${end.toString()}`;
      } else {
        // line
        return `${path} L ${end.toString()}`;
      }
    }
  }, "");
}

const handleHover = camp => e => {
  e.target.style.fill = camp.color;
};

const handleOut = camp => e => {
  e.target.style.fill = "#444";
};

function makeRange(from, to, step) {
  const steps = [];
  for (let i = from; i <= to; i += step) {
    steps.push(i);
  }
  return steps;
}

function points(count: number, radius: number, offset = 0) {
  const angle = 360 / count;
  const vertexIndices = range(count);

  return vertexIndices.map(index => {
    return {
      theta: offset + degreesToRadians(offset + angle * index),
      r: radius
    };
  });
}

const handleDotClick = pt => evt => alert(pt.toString());

const totalRadius = 8200;
const [width, height, scale] = [totalRadius / 5, totalRadius / 5, 0.1];

const Outline = () => {
  const inside = streetToRadius("Esp")
  const outside = streetToRadius("L")
  const pt0 = polarToCartesian(0, 0, outside, 300);
  const pt1 = polarToCartesian(0, 0, inside, 300);
  const pt2 = polarToCartesian(0, 0, inside, 60);
  const pt3 = polarToCartesian(0, 0, outside, 60);

  return (
    <path
      d={`${describeArc(0, 0, outside, 60, 300)} L ${pt2} A ${inside}, ${inside}, 0, 1, 1, ${
        pt1[0]
      } ${pt1[1]} ${pt1} z`}
      style={{ stroke: "none", strokeWidth: 0, fill: "#A8ABAE" }}
      ref={el => {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }}
    />
  );
};

const TrashFence = () => {
  const pts = [
    [0, -totalRadius],
    polarToCartesian(0, 0, totalRadius, 72),
    polarToCartesian(0, 0, totalRadius, 144),
    polarToCartesian(0, 0, totalRadius, 216),
    polarToCartesian(0, 0, totalRadius, 288)
  ]
    .map(p => p.toString())
    .join(", ");
  return <polygon className="trash-fence" points={pts} />;
};

const Plaza = ({ time, street, radius }: { time: string; street?: string; radius?:number }) => {
  const [x, y] = polarToCartesian(
    0,
    0,
    radius ? radius : streetToRadius(street),
    timeToDegrees(time)
  );
  return <circle cx={x} cy={y} r={130} className="plaza" />;
};

const Spoke = ({ time, startStreet = "esp", endStreet="L" }: { time: string; startStreet?: string; endStreet?:string }) => {
  const start = polarToCartesian(
    0,
    0,
    streetToRadius(startStreet),
    timeToDegrees(time)
  );
  const end = polarToCartesian(0, 0, streetToRadius(endStreet),, timeToDegrees(time));
  return <line x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]} />;
};

const App = () => (
  <div id="container">
    <SideBar />

    <div id="map-container">
      <svg viewBox={[0, 0, width, height].join(" ")}>
        <g transform={`translate(${width / 2},${height / 2}), scale(${scale})`}>
          <defs>
            <clipPath id="trash-fence">
              <TrashFence />
            </clipPath>
          </defs>

          <TrashFence />

          <g id="grid" clipPath="url(#trash-fence)">
            {points(48, totalRadius)
              .map(toCartesian)
              .map((pt, idx) => (
                <line key={idx} x1={0} y1={0} x2={pt[0]} y2={pt[1]} />
              ))}
            {makeRange(700, totalRadius, 100).map(radius => (
              <circle key={radius} r={radius} />
            ))}
          </g>

          <g id="the-man">
            <circle r={600} style={{ stroke: "#0d59ff", fill: "#A8ABAE" }} />
            <circle r={400} style={{ fill: "#ccc" }} />
          </g>

          <g id="the-temple" transform={"translate(0, -2500)"}>
            <circle r={300} style={{ fill: "#A8ABAE", stroke: "#0d59ff" }} />
            <circle r={125} style={{ fill: "#ccc" }} />
          </g>

          <Outline />

          <g id="roads">
            <g id="streets">
              <path d={describeArc(0, 0, streetToRadius("Esp"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("A"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("B"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("C"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("D"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("E"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("F"), timeToDegrees("2:30"), timeToDegrees("3:30"))} />
              <path d={describeArc(0, 0, streetToRadius("F"), timeToDegrees("4:00"), timeToDegrees("5:00"))} />
              <path d={describeArc(0, 0, streetToRadius("F"), timeToDegrees("7:00"), timeToDegrees("8:00"))} />
              <path d={describeArc(0, 0, streetToRadius("F"), timeToDegrees("8:30"), timeToDegrees("9:30"))} />

              <path d={describeArc(0, 0, streetToRadius("G"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("H"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("I"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("J"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("K"), 60, 300)} />
              <path d={describeArc(0, 0, streetToRadius("L"), 60, 300)} />
            </g>
            <g id="spokes">
              <Spoke time={"2:00"} />
              <Spoke time={"2:15"} startStreet="G" />
              <Spoke time={"2:30"} />
              <Spoke time={"2:45"} startStreet="G" />
              <Spoke time={"3:00"} startStreet="center" />
              <Spoke time={"3:15"} startStreet="G" />
              <Spoke time={"3:30"} />
              <Spoke time={"3:45"} startStreet="G" />
              <Spoke time={"4:00"} />
              <Spoke time={"4:15"} startStreet="G" />
              <Spoke time={"4:30"} />
              <Spoke time={"4:45"} startStreet="G" />
              <Spoke time={"5:00"} />
              <Spoke time={"5:15"} startStreet="G" />
              <Spoke time={"5:30"} />
              <Spoke time={"5:45"} startStreet="G" />
              <Spoke time={"6:00"} startStreet="center" />
              <Spoke time={"6:15"} startStreet="G" />
              <Spoke time={"6:30"} />
              <Spoke time={"6:45"} startStreet="G" />
              <Spoke time={"7:00"} />
              <Spoke time={"7:15"} startStreet="G" />
              <Spoke time={"7:30"} />
              <Spoke time={"7:45"} startStreet="G" />
              <Spoke time={"8:00"} />
              <Spoke time={"8:15"} startStreet="G" />
              <Spoke time={"8:30"} />
              <Spoke time={"8:45"} startStreet="G" />
              <Spoke time={"9:00"} startStreet="center" />
              <Spoke time={"9:15"} startStreet="G" />
              <Spoke time={"9:30"} />
              <Spoke time={"9:45"} startStreet="G" />
              <Spoke time={"10:00"} />
              <Spoke time={"00:00"} startStreet="center" endStreet="Esp" />
            </g>
          </g>

          {camps.main.map(camp => (
            <path key={camp.name} d={coordsToPath(camp.coords)} />
          ))}

          <g
            id="center-camp"
            transform={`translate(0, ${streetToRadius("a") - 40})`}
          >
            <circle x={0} y={0} r={750} fill="#A8ABAE" />
            <circle x={0} y={0} r={30} fill="#104a92" />
            <g transform="scale(5)">
              {camps.center.map(camp => (
                <path
                  onMouseOver={handleHover(camp)}
                  onMouseOut={handleOut(camp)}
                  key={camp.name}
                  d={coordsToPath(camp.coords)}
                />
              ))}
            </g>

            <g className="dots">
              {points(360, 50)
                .map(toCartesian)
                .map((pt, idx) => (
                  <circle
                    onClick={handleDotClick(pt)}
                    key={idx}
                    cx={pt[0]}
                    cy={pt[1]}
                    r={0.4}
                  />
                ))}

              {points(360, 100)
                .map(toCartesian)
                .map((pt, idx) => (
                  <circle
                    onClick={handleDotClick(pt)}
                    key={idx}
                    cx={pt[0]}
                    cy={pt[1]}
                    r={0.5}
                  />
                ))}

              {points(360, 150)
                .map(toCartesian)
                .map((pt, idx) => (
                  <circle
                    onClick={handleDotClick(pt)}
                    key={idx}
                    cx={pt[0]}
                    cy={pt[1]}
                    r={0.5}
                  />
                ))}
            </g>


            <circle r={750} style={{ strokeWidth: '20px', fill: 'none', vectorEffect: 'none', stroke: '#ccc' }} />
          </g>

          <Plaza time="3:00" street="B" />
          <Plaza time="3:00" street="G" />

          <Plaza time="4:30" street="G" />

          <Plaza time="6:00" radius={streetToRadius("A") - 40} />

          <Plaza time="6:00" street="I" />

          <Plaza time="7:30" street="G" />

          <Plaza time="9:00" street="B" />
          <Plaza time="9:00" street="G" />
        </g>
      </svg>
    </div>
  </div>
);

render(<App />, document.getElementById("app"));
