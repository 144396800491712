import { types } from "mobx-state-tree";

const Drawing = types
  .model("Drawing", {
    points: types.array(
      types.model({
        x: types.number,
        y: types.number
      })
    )
  })
  .views(self => ({
    get path(): string {
      return self.points.map(({ x, y }) => `${x} ${y}`).join(", ");
    }
  }))
  .actions(self => ({
    addPoint(x, y) {
      self.points.push({ x, y });
    }
  }));

export default Drawing;
